
import { defineComponent, onMounted, ref, watch, computed } from "vue"
import { phoneMask } from "@/core/helpers/masks"
import { mask } from "vue-the-mask"

import { Delete, Edit, Plus, Star, StarFilled } from "@element-plus/icons-vue"
import { EmailAddress } from "@/core/models/EmailAddress"
import { Phone } from "@/core/models/Phone"
import { Address } from "@/core/models/Address"
import { ExtendedContact } from "@/core/models/ExtendedContact"
import ApiService from "@/core/services/ApiService"
import { useRouter, useRoute } from "vue-router"
import { ContactTagEnum, ContactTypeEnum, staticContactTagList } from "@/core/enums/enums"
import { getRule, RuleTypes } from "@/core/helpers/rules"
import { ElForm } from "element-plus"
import swalConfirm from "@/core/helpers/swal-confirm"
import { SelectModel } from "@/core/models/SelectModel"
import { useStore } from "vuex"

export default defineComponent({
  name: "student-contact",
  inheritAttrs: false,
  components: {},
  props: ["personId", "studentId", "studentName", "summaryContact"],
  emits: ["close", "getSummaryList"],
  directives: { mask },
  setup(props, { emit }) {
    const store = useStore()
    const isDrawerOpen = ref(false)

    const router = useRouter()
    const route = useRoute()

    const command = ref()

    const phone = ref<Phone>({
      contactTag: ContactTagEnum.Primary,
      personId: props.personId,
    } as Phone)
    const email = ref<EmailAddress>({
      contactTag: ContactTagEnum.Primary,
      personId: props.personId,
    } as EmailAddress)
    const address = ref<Address>({
      contactTag: ContactTagEnum.Primary,
      personId: props.personId,
    } as Address)

    const contactList = ref<ExtendedContact[]>([])
    const cityList = ref<SelectModel[]>([])
    const districtList = ref<SelectModel[]>([])

    watch(
      () => props.studentId,
      async newValue => {
        if (newValue) {
          await getContactList(newValue)
          phone.value.personId = props.personId
          email.value.personId = props.personId
          address.value.personId = props.personId
        }
      }
    )

    watch(
      () => props.summaryContact,
      async newValue => {
        if (newValue) {
          await handleCommand(newValue)
        }
      }
    )

    const personList = computed(() => {
      const list = store.getters.getStudentParentList.map(x => ({
        id: x.personId,
        name: (x.name + " " + x.surname).toLocaleUpperCase("tr-TR"),
        relationShip: x.relationShipName,
      }))
      list.push({
        id: props.personId,
        name: props.studentName,
        relationShip: "Öğrenci",
      })
      return list
    })

    type FormInstance = InstanceType<typeof ElForm>

    const ruleFormRef = ref<FormInstance>()

    const rules = computed(() => {
      switch (command.value) {
        case "phone":
          return {
            phone: getRule(RuleTypes.PHONE, "Telefon"),
            contactTag: getRule(RuleTypes.SELECT, "İletişim Tipi"),
          }
        case "address":
          return {
            addressLine: getRule(RuleTypes.TEXT100, "Adres"),
            cityId: getRule(RuleTypes.SELECT, "Şehir"),
            districtId: getRule(RuleTypes.SELECT, "İlçe"),
            contactTag: getRule(RuleTypes.SELECT, "İletişim Tipi"),
          }
        case "email":
          return {
            email: getRule(RuleTypes.MAIL, "Email Adresi"),
            contactTag: getRule(RuleTypes.SELECT, "İletişim Tipi"),
          }

        default:
          return {}
      }
    })

    const computedContactModel = computed(() => {
      switch (command.value) {
        case "email":
          return email.value
        case "address":
          return address.value
        case "phone":
          return phone.value

        default:
          return {}
      }
    })

    const getCityList = async () => {
      const { data } = await ApiService.get("location/cities")
      cityList.value = data
    }

    const getDistrictList = async cityId => {
      const { data } = await ApiService.get("location/cities/" + cityId + "/districts")
      districtList.value = data
    }

    const getContactList = async studentId => {
      const { data } = await ApiService.get("student/" + studentId + "/contact")
      contactList.value = data
    }

    const handleAddOrUpdate = async formEl => {
      if (!formEl) return
      formEl.validate(async valid => {
        if (valid) {
          switch (command.value) {
            case "email":
              await addorUpdateEmail(email.value)
              break
            case "address":
              await addorUpdateAddress(address.value)
              break
            case "phone":
              await addorUpdatePhone({
                ...phone.value,
                phone: phone.value.phone
                  .replaceAll(" ", "")
                  .replaceAll("(", "")
                  .replaceAll(")", ""),
              })
              break

            default:
              return {}
          }
          await getContactList(route.params.student_id)
          isDrawerOpen.value = false
          emit("getSummaryList")
        }
      })
    }

    const addorUpdateEmail = async data => {
      if (data.id) {
        await ApiService.post("email-address/person/edit", data)
      } else {
        await ApiService.post("email-address/person/add", data)
      }
    }
    const addorUpdateAddress = async data => {
      if (data.id) {
        await ApiService.post("address/person/edit", data)
      } else {
        await ApiService.post("address/person/add", data)
      }
    }
    const addorUpdatePhone = async data => {
      if (data.id) {
        await ApiService.post("phone-number/person/edit", data)
      } else {
        await ApiService.post("phone-number/person/add", data)
      }
    }

    const getEmail = async id => {
      const { data } = await ApiService.get("email-address/" + id + "/person")
      email.value = data
    }
    const getAddress = async id => {
      const { data } = await ApiService.get("address/" + id + "/person")
      await getDistrictList(data.cityId)
      address.value = data
    }
    const getPhone = async id => {
      const { data } = await ApiService.get("phone-number/" + id + "/person")
      phone.value = data
    }

    const handleRemove = async row => {
      const swalResult = await swalConfirm()
      let operation
      if (swalResult.isConfirmed) {
        switch (row.contactType) {
          case ContactTypeEnum.EmailAddress:
            operation = "email-address"
            break
          case ContactTypeEnum.Address:
            operation = "address"
            break
          case ContactTypeEnum.Phone:
            operation = "phone-number"
            break

          default:
            return {}
        }
        await ApiService.get(operation + "/person/remove/" + row.id)
        await getContactList(route.params.student_id)
      }
    }

    const handleEdit = async row => {
      switch (row.contactType) {
        case ContactTypeEnum.EmailAddress:
          await getEmail(row.id)
          handleCommand("email")
          break
        case ContactTypeEnum.Address:
          await getAddress(row.id)
          handleCommand("address")
          break
        case ContactTypeEnum.Phone:
          await getPhone(row.id)
          handleCommand("phone")
          break
        default:
          return {}
      }
    }

    const handleCommand = args => {
      command.value = args
      isDrawerOpen.value = !isDrawerOpen.value
    }

    onMounted(async () => {
      if (!route.params.student_id) router.push("dashboard-summary")
      await getCityList()
      await getContactList(route.params.student_id)
      resetForms()
    })

    const resetForms = () => {
      phone.value = { contactTag: ContactTagEnum.Primary, personId: props.personId } as Phone
      email.value = { contactTag: ContactTagEnum.Primary, personId: props.personId } as EmailAddress
      address.value = { contactTag: ContactTagEnum.Primary, personId: props.personId } as Address

      ruleFormRef.value?.resetFields()
      emit("close")
    }

    return {
      Delete,
      Edit,
      Plus,
      Star,
      StarFilled,

      isDrawerOpen,

      phoneMask,

      phone,
      email,
      address,
      command,
      computedContactModel,

      contactList,
      staticContactTagList,
      cityList,
      districtList,
      personList,

      resetForms,
      handleAddOrUpdate,
      handleRemove,
      handleEdit,
      handleCommand,
      getDistrictList,

      rules,
      ruleFormRef,
    }
  },
})
